<template>
  <div>
    <v-data-table :headers="headers" :items="certificate_settings" sort-by="calories">
      <template v-slot:top>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn icon color="primary" rounded 
            @click="$router.push({name: 'EditCertificateConfig', params: {id: item.id}})"
            :disabled="$route.name == 'EditCertificateConfig' && $route.params.id == item.id"
          ><v-icon>mdi-pencil</v-icon></v-btn>
        </div>
      </template>
      <template v-slot:[`item.level`]="{ item }">
        {{item.level == 'director' ? 'Diretor Latino-Americano' : 'Diretor Nacional'}}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip label small dark :color="item.status == 'active' ? 'green' : 'error'">
          {{item.status == 'active' ? 'Ativo' : 'Inativo'}}
        </v-chip>
      </template>
    </v-data-table>    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'CertificateList',
  data: () => ({
    headers: [
      { text: 'Nome', value: 'name', align: 'start'},
      { text: 'Cargo', value: 'role' },
      { text: 'Nível', value: 'level' },
      { text: 'Status', value: 'status', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    certificate_settings: [],
  }),
  methods: {
    getSettingList(){
      Api.CertificateSetting.index()
        .then( r=>r.data)
        .then(d => this.certificate_settings = d)
        .catch((err) => this.addNotification({type: 'error', msg: `${err}`}));
    },
  },
  mounted () {
    this.getSettingList()
  },
}
</script>

<style scoped>
</style>